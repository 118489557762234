import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./index@gen.scss";

const GeneratedSolutionsIndex = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDutyソリューション｜PagerDuty正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyは中断の最小化、脅威への対応、危機管理の実行、チャットやビデオツール等との統合を支援します。ケースごとの活用例をご覧ください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyは中断の最小化、脅威への対応、危機管理の実行、チャットやビデオツール等との統合を支援します。ケースごとの活用例をご覧ください。"
        />

        <meta
          property="og:title"
          content="PagerDutyソリューション｜PagerDuty正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyは中断の最小化、脅威への対応、危機管理の実行、チャットやビデオツール等との統合を支援します。ケースごとの活用例をご覧ください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/solutions//"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/solutions//"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow=""
        textGreen="ソリューション"
        overview=""
      />

      <div className="solutionsWrapper">
        <div className="px-[25px] xl:px-0">
          <h2 className="text-2xl leading-8 text-black text-center after:w-0 mb-10">
            多くの業種業界・業務目的で活用されるPagerDuty
          </h2>
          <p className="text-lg leading-8 text-black text-center mb-[100px]">
            PagerDutyは、脅威を軽減しつつ生産性を最大化し、多くのユースケースで素晴らしいエクスペリエンスを提供しています。革新的な方法で業務を変革するケースごとの活用例をご覧ください。
          </p>
          <h3 className="text-2xl leading-8 text-black text-center mb-[50px]">
            PagerDuty業務目的別活用例
          </h3>
          <div className="grid [&_img]:mx-auto grid-cols-1 md:grid-cols-3 gap-x-[30px] gap-y-16 mb-[100px]">
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/dev_image_2_93713c592b.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="開発者"
              />
              <a
                href="/use-cases/developer/"
                className="mb-5 md:mb-[52px] block text-black text-xl leading-8 font-bold"
              >
                開発者
              </a>
              <p className="mb-5 text-center">
                PagerDutyを使って、より多くの時間をイノベーションに費やす
              </p>
              <a
                href="/use-cases/developer/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/incident_response_image_15d61c2c85.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="開発者"
              />
              <a
                href="/use-cases/security-incident-response/"
                className="mb-5 block text-black text-xl leading-8 font-bold max-w-[280px] mx-auto"
              >
                セキュリティーインシデント対応
              </a>
              <p className="mb-5 text-center">
                脅威への迅速な対応、リアルタイムな運用によるセキュリティー
              </p>
              <a
                href="/use-cases/security-incident-response/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/critical_event_management_65b53d39dd.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="開発者"
              />
              <a
                href="/use-cases/critical-event-management/"
                className="mb-5 md:mb-[52px] block text-black text-xl leading-8 font-bold"
              >
                危機管理
              </a>
              <p className="mb-5 text-center">
                PagerDutyで緊急の仕事に優先順位をつけ、効率的な管理を実現
              </p>
              <a
                href="/use-cases/critical-event-management/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
            <div className="flex flex-col justify-between transition-all duration-300 [&_a]:cursor-pointer text-center">
              <div>
                <img
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/service_ownership_image_00f4307240.svg"
                  width="340px"
                  height="200px"
                  loading="lazy"
                  className="mb-5"
                  alt="サービスオーナーシップ"
                />
                <a
                  href="/use-cases/service-ownership/"
                  className="mb-5 md:mb-[52px] block text-black text-xl leading-8 font-bold"
                >
                  サービスオーナーシップ
                </a>
                <p className="mb-5 text-center">
                  チームに明確な責任を与え、継続的な改善と革新を推進
                </p>
              </div>
              <div className="mt-auto">
                <a
                  href="/use-cases/service-ownership/"
                  className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group mt-auto"
                >
                  詳しくはこちら
                  <svg
                    className="w-[11px] h-[15px]"
                    width="{11}"
                    height="{15}"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_1019_794" fill="white">
                      <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                    </mask>
                    <path
                      d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                      fill="black"
                      className="group-hover:fill-[#4EB346]"
                    />
                    <path
                      d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                      fill="black"
                      className="group-hover:fill-[#4EB346]"
                      mask="url(#path-1-inside-1_1019_794)"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/collabops_image_a937bebd9a.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="CollabOpsツールのインテグレーション"
              />
              <a
                href="/use-cases/collabops/"
                className="mb-5 block text-black text-xl leading-8 font-bold"
              >
                CollabOpsツールのインテグレーション
              </a>
              <p className="mb-5 text-center">
                Slack、Teams、Zoomなどの統合でプロセスを合理化
              </p>
              <a
                href="/use-cases/collabops/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
          </div>
          <h3 className="text-2xl leading-8 text-black text-center mb-[50px]">
            PagerDuty業種業界別活用例
          </h3>
          <div className="grid [&_img]:mx-auto grid-cols-1 md:grid-cols-3 gap-x-[30px] gap-y-16 [&_a]:transition-all [&_a]:duration-300">
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/dev_image_3_f163ca06f9.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="小売業"
              />
              <a
                href="/industries/retails/"
                className="mb-5 block text-black text-xl leading-8 font-bold"
              >
                小売業
              </a>
              <p className="mb-5 text-center max-w-[220px] mx-auto">
                素晴らしいカスタマーエクスペリエンスを提供
              </p>
              <a
                href="/industries/retails/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/incident_response_image_1_f3c7e582b0.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="金融サービス"
              />
              <a
                href="/industries/financial-service/"
                className="mb-5 block text-black text-xl leading-8 font-bold"
              >
                金融サービス
              </a>
              <p className="mb-5 text-center max-w-[220px] mx-auto">
                金融サービスの未来を支える
              </p>
              <a
                href="/industries/financial-service/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/critical_event_management_2_354c9024bc.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="ヘルスケア"
              />
              <a
                href="/industries/healthcare/"
                className="mb-5 block text-black text-xl leading-8 font-bold"
              >
                ヘルスケア
              </a>
              <p className="mb-5 text-center max-w-[220px] mx-auto">
                ペイシェントジャーニーにおける信頼性の向上
              </p>
              <a
                href="/industries/healthcare/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
            <div className="transition-all duration-300 [&_a]:cursor-pointer text-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/service_ownership_image_1_112007647c.svg"
                width="340px"
                height="200px"
                loading="lazy"
                className="mb-5"
                alt="非営利団体"
              />
              <a
                href="/industries/nonprofit/"
                className="mb-5 block text-black text-xl leading-8 font-bold"
              >
                非営利団体
              </a>
              <p className="mb-5 text-center max-w-[220px] mx-auto">
                ミッションの遂行をリアルタイムに、いつでも
              </p>
              <a
                href="/industries/nonprofit/"
                className="text-lg leading-8 text-black flex justify-center items-center gap-x-1 group"
              >
                詳しくはこちら
                <svg
                  className="w-[11px] h-[15px]"
                  width="{11}"
                  height="{15}"
                  viewBox="0 0 11 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_1019_794" fill="white">
                    <path d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z" />
                  </mask>
                  <path
                    d="M0.954609 15L10.3367 7.49988L0.954609 0L0.662109 0.366184L9.58604 7.49988L0.662109 14.6338L0.954609 15Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                  />
                  <path
                    d="M0.954609 15L-17.7974 29.9787L-2.81297 48.7379L15.9405 33.7463L0.954609 15ZM10.3367 7.49988L25.3226 26.2462L48.7733 7.49957L25.3223 -11.2467L10.3367 7.49988ZM0.954609 0L15.9402 -18.7465L-2.8132 -33.7376L-17.7974 -14.9787L0.954609 0ZM0.662109 0.366184L-18.0899 -14.6125L-33.063 4.13249L-14.3236 19.1126L0.662109 0.366184ZM9.58604 7.49988L24.5721 26.2461L48.0223 7.49956L24.5717 -11.2466L9.58604 7.49988ZM0.662109 14.6338L-14.3239 -4.11237L-33.0628 10.8678L-18.0899 29.6125L0.662109 14.6338ZM15.9405 33.7463L25.3226 26.2462L-4.64913 -11.2464L-14.0313 -3.74629L15.9405 33.7463ZM25.3223 -11.2467L15.9402 -18.7465L-14.031 18.7465L-4.64884 26.2464L25.3223 -11.2467ZM-17.7974 -14.9787L-18.0899 -14.6125L19.4141 15.3449L19.7066 14.9787L-17.7974 -14.9787ZM-14.3236 19.1126L-5.39966 26.2463L24.5717 -11.2466L15.6478 -18.3803L-14.3236 19.1126ZM-5.39998 -11.2463L-14.3239 -4.11237L15.6481 33.38L24.5721 26.2461L-5.39998 -11.2463ZM-18.0899 29.6125L-17.7974 29.9787L19.7066 0.0212835L19.4141 -0.3449L-18.0899 29.6125Z"
                    fill="black"
                    className="group-hover:fill-[#4EB346]"
                    mask="url(#path-1-inside-1_1019_794)"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedSolutionsIndex;
